
































































import { defineComponent } from '@vue/composition-api'
import { TenderDTO } from '@/services/tender/types'
import { formatDate } from '@/utils/helpers'
import Timer from '@/components/Timer.vue'

export default defineComponent({
  setup() {
    return { formatDate }
  },
  name: 'EvaluationDetailsHeader',
  props: {
    procurement: {
      type: Object as () => TenderDTO,
      required: true,
    },
  },
  components: { Timer },
})
