
























































































































import { defineComponent } from '@vue/composition-api'
import {
  errorMessages,
  formatDate,
  formatTime,
  formatMoney,
  renderDeliveryProfile,
  parseAndJoin,
} from '@/utils/helpers'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import CancelButton from '@/components/buttons/CancelButton.vue'
import OfferStatus from '@/views/Tender/partials/OfferStatus.vue'
import TenderService from '@/services/tender'
import { LotWithOffersDTO, TenderWithOffersDTO } from '@/services/tender/types'
import ConfirmOffer from '@/views/Tender/Evaluation/partials/ConfirmOffer.vue'
import { VueEditor } from 'vue2-editor'
import { useErrors } from '@/components/composables/useErrors'

export default defineComponent({
  name: 'EvaluationOffers',
  components: { ConfirmOffer, OfferStatus, SubmitButton, CancelButton, VueEditor },
  setup() {
    const { errors, clearFieldError } = useErrors()

    return {
      formatDate,
      formatTime,
      formatMoney,
      renderDeliveryProfile,
      parseAndJoin,
      errors,
      clearFieldError,
      activeKey: 0,
    }
  },
  props: {
    lots: {
      type: Array as () => LotWithOffersDTO[],
      required: true,
    },
  },
  computed: {
    activeLot(): LotWithOffersDTO {
      return (
        this.lots[this.activeKey] || {
          id: 0,
          capacityUnit: '',
          power: 0,
          quantity: 0,
          totalLotHours: 0,
          totalConfirmedPower: 0,
          startDate: '',
          endDate: '',
          weekDay: '',
          startTime: '',
          endTime: '',
          cbcCoverage: true,
          crossBorderCapacity: {},
          deliveryProfile: [],
          lotBids: [],
          direction: '',
        }
      )
    },
    activeLotCBCDirection(): string {
      return this.activeLot.crossBorderCapacity ? this.activeLot.crossBorderCapacity.direction : '-'
    },
    activeLotCBCCapacity(): number {
      return this.activeLot.crossBorderCapacity ? this.activeLot.crossBorderCapacity.leftoverCapacity : 0
    },
  },
  methods: {
    setErrors(errors: { [x: string]: string[] }) {
      this.errors.value = errors
    },
    calculatedTotal(quantity: number, unit: number) {
      return unit ? quantity * unit : 0
    },
    async confirm(data: TenderWithOffersDTO): Promise<void> {
      this.$emit('updated', data)
    },
    async revert(id: number): Promise<void> {
      const loader = this.$loading.show()
      try {
        const response = await TenderService.revertBid(id)
        if (response) {
          this.$toast.success(response.data.message)
        }
        this.$emit('updated', response.data.data)
      } catch (err: any) {  // eslint-disable-line
        if (err.response.data) {
          this.$toast.error(errorMessages(err.response.data.message))
          return
        }
        this.$toast.error(this.$t('somethingWentWrong') as string)
      } finally {
        loader.hide()
      }
    },
  },
})
