
















import Vue from 'vue'

export default Vue.extend({
  name: 'SubmitButton',
  props: {
    color: {
      type: String,
      default: 'green',
    },
  },
  computed: {
    btnColor: {
      get(): string {
        if (this.color === 'green') {
          return '#36A7AA'
        } else if (this.color === 'red') {
          return 'red'
        }
        return '#36A7AA'
      },
      set(value: string): void {
        this.$emit('input', value)
      },
    },
  },
})
